input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.btn-primary {
  --bs-btn-hover-bg: #949494 !important;
}
.customer-forms-wrapper {
  background: white;
  margin-left: 32px !important;
  margin-top: 20px !important;
  margin-right: 32px !important;
  border-radius: 12px 12px 0px 0px !important;
  width: auto !important;
  padding-bottom: 50px;
}

.customer-forms-wrapper .type-button {
  font-size: 18px;
  font-weight: 800;
  color: #969597;
}

.accordion-security-item {
    border: 1px solid #eaeaec;
    border-radius: 24px;
}

.accordion-security-item hr {
    margin-left: 3%;
    margin-right: 3%;
}

.accordion-title.type-button {
    cursor: pointer;
}
.accordion-title.type-button .arrow-icon {
    position: absolute;
    top: 28px;
    right: 24px;
}
.accordion-title.type-button.open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.customer-forms-wrapper .left-panel {
  margin-top: 32px;
  margin-left: 32px;
  margin-right: 40px;
  padding-left: 0px;
  padding-right: 0px;
}

.customer-forms-wrapper .left-panel div {
  margin-bottom: 32px;
  cursor: pointer;
  vertical-align: middle;
}

.customer-forms-wrapper .left-panel div:hover .arrow-icon {
  opacity: 1;
}

.customer-forms-wrapper .left-panel .arrow-icon {
  vertical-align: middle;
  float: right;
  margin-bottom: -4px;
  margin-left: auto;
  margin-right: 22px;
  opacity: 0;
  transition: 0.15s;
}

.customer-forms-wrapper .form-wrapper {
  margin-top: 32px;
  border: 1px solid #eaeaec;
  margin-right: 32px;
  border-radius: 12px;
  padding: 0;
  padding-bottom: 12px;
  height: fit-content;
}
.customer-forms-wrapper .form-wrapper .form-header {
  background: #f9fafb;
  margin: 0px;
  padding-top: 17px;
  padding-bottom: 17px;
  border-radius: 12px 12px 0px 0px;
}
.customer-forms-wrapper .form-wrapper .form-header p {
  margin-left: 32px;
  font-size: 18px;
  font-weight: 800;
  color: #969597;
}

.container {
  background: white;
  width: 100vw;
}

.type-button {
  background-color: #f9fafb;
  border-radius: 12px;
  border: 1px solid #eaeaec;
  padding: 16px 0px 16px 32px;
  display: flex;
  align-items: center;
}

.type-button.active {
  background-color: #f9fafb;
  color: #f3ac3c;
  border-radius: 12px;
  border: 1px solid #f3ac3c;
  padding: 16px 0px 16px 32px;
  transition: .15s;
}

.custom-dropdown button,
.custom-dropdown .btn:first-child:active,
.custom-dropdown button:hover,
.custom-dropdown .btn.show {
  background-color: transparent;
  color: black;
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid #b7b7b9;
  width: 100%;
  height: 34px;
  margin-top: -4px;
}

.dropdown-menu {
  width: 100%;
  margin-top: 10px !important;
  top: -4px !important;
  border-radius: 8px !important;
  background: white !important;
  border: 1px solid #B7B7B9 !important;
  padding-right: 4px !important;
}

.dropdown-menu .scroll-mask {
  max-height: 272px;
  overflow-x: hidden;
  overflow-y: auto;
}

.custom-dropdown .btn:focus-visible,
.custom-dropdown .btn:first-child:active:focus-visible {
  box-shadow: none;
}
.custom-dropdown .dropdown-item {
  width: 96%;
  margin-left: 2%;
  border-radius: 6px;
  margin-top: 4px;
  margin-bottom: 4px;
}
.custom-dropdown .dropdown-item:active {
  background-color: transparent;
  color: black;
  margin-left: 2px;
}

.custom-dropdown .dropdown-item:hover {
  background: #F8F8F8;
}

.custom-dropdown .dropdown-toggle {
  text-align: left;
  color: black;
  margin-left: 0px;
  padding-left: 2px;
  padding-bottom: 3px;
}

.custom-dropdown .dropdown-toggle.show {
  border-bottom: 1px solid #f3ac3c;
  transition: .15s;
}

.custom-dropdown .dropdown-toggle::after {
  float: right;
}

.selected-option {
  color: #f3ac3c !important;
}

.form-input {
  display: block;
  height: 30px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.07);
  margin-top: 0px;
  outline: none;
  border: none;
  border-bottom: 1px solid #b7b7b9;
  transition: 0.15s;
}
.form-input.form-input-readonly {
    border-bottom: none;
}
.form-input:focus {
  border-bottom: 1px solid #f3ac3c;
  transition: 0.15s;
}
.form-input.form-input-readonly:focus {
  border-bottom: none;
}
.kyc-form {
  margin-top: 16px;
}
.input-container {
  display: inline-block;
  margin-top: 6px;
  width: calc(50% - 64px);
  margin-left: 32px;
  margin-right: 32px;
}

.input-container:focus-within label {
  color: #f3ac3c;
  transition: 0.15s ease-in-out;
}
.input-container:focus-within input{
  border-color: #f3ac3c;
  transition: 0.15s ease-in-out;
}

.input-container:focus-within .clear-val-btn {
  opacity: 1;
  right: 12px;
  transition: 0.15s ease-in-out;
  cursor: pointer;
}

.input-container:focus-within .clear-val-btn-select  {
  opacity: 1;
  right: 12px;
  transition: 0.15s ease-in-out;
  cursor: pointer;
}

.input-container:focus-within .down-arrow-select {
  opacity: 1;
  right: 8px;
  transition: .3s ease-in-out;
  cursor: pointer;
  transform: rotate(180deg);
  color: #f3ac3c !important;
}

.input-container .clear-val-btn {
  opacity: 0;
  position: relative;
  right: 14px;
  top: -24px;
  float: right;
  cursor: default;
}
.input-container .clear-val-btn-select {
  opacity: 0;
  position: relative;
  right: 12px;
  top: -24px;
  float: right;
  cursor: default;
}

.dropdown-toggle::after {
  content: "" !important;
  border: none !important;
}

.down-arrow-select {
  opacity: 1;
  position: relative;
  right: 8px;
  top: -26px;
  float: right;
  cursor: default;
  color: #b7b7b9 !important;
}

.check-icon{
  opacity: 1;
  position: relative;
  right: 0px;
  top: 5px;
  float: right;
  cursor: default;
}

.input-header {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  color: #969597;
  text-transform: uppercase;
}

.input-container-readonly .input-header,
.input-container-readonly .form-input {
  pointer-events: none;
}

.input-container-readonly .form-input {
  border-bottom:none;
}

.input-container-readonly .clear-val-btn {
  display: none;
}

.f-wrapper {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
}

.action-buttons-wrapper {
  position: fixed;
  bottom: -50px;
  width: calc(100% - 64px) !important;
  background: #fefefe;
  margin-left: 32px !important;
  margin-top: 0px !important;
  margin-right: 32px !important;
  padding-top: 16px;
  padding-bottom: 16px;
  box-shadow: 0px -3px 10px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 41px;
  border-radius: 0px 0px 12px 12px;
}
.action-buttons-wrapper .action-buttons {
  display: flex;
  justify-content: flex-end;
}

.action-buttons-wrapper .action-buttons button {
  width: 104px;
  height: 40px;
  border-radius: 6px;
}

.action-buttons-wrapper .action-buttons .btn-save {
  margin-right: 32px;
  color: white;
  background: #f3ac3c;
  border: 1px solid #c78d31;
  font-weight: 600;
}

.action-buttons-wrapper .action-buttons .btn-cancel {
  margin-right: 40px;
  color: black;
  background: white;
  border: 1px solid #eaeaec;
  font-weight: 600;
}

/* SCROLLING SELECT */
.dropdown-menu .scroll-mask::-webkit-scrollbar {
  width: 3px;
}


.dropdown-menu .scroll-mask::-webkit-scrollbar-track {
  background: #eaeaec;
  border-radius: 12px;
  margin-block: 4px;
}


.dropdown-menu .scroll-mask::-webkit-scrollbar-thumb {
  background: #b7b7b9;
  border-radius: 6px;
}

.dropdown-menu .scroll-mask::-webkit-scrollbar-thumb:hover {
  background: #b7b7b9;
}

.dropdown-menu .scroll-mask {
  overflow-y: auto;
  overflow-x: hidden;
}
.dropdown-menu .scroll-mask thead th {
  position: sticky;
  top: 0px;
}

/* END SCROLLING SELECT */

/* DATE PICKER */

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}
.custom-date-input {
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
}

.datepicker-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
}

.datepicker-wrapper:focus-within {
  border-color: 1px solid #f3ac3c;
}

.calendar-icon {
  scale: 0.6;
  margin-left: -34px;
  cursor: pointer;
}

.customDatePickerInput {
  border: none;
  width: 100%;
  border-bottom: 1px solid #B7B7B9;
  padding: 5px 22px 2px 0px;
  font-size: 16px;
}

.customDatePickerInput:focus-visible {
  outline: none;
}

.customDatePickerInput:focus-visible {
  outline: none;
}

.customDatePickerCalendar {
  position: absolute;
  top: 100%;
  left: 100%;
  transform: translateX(-100%);
  padding: 10px;
  z-index: 100;
  border-color: #EAEAEC !important;
  box-shadow:
    0px 0px 3px 0px rgba(0, 0, 0, 0.15),
    0px 0px 3px -5px rgba(0, 0, 0, 0.15);
}

.customDatePickerCalendar .react-datepicker__header {
  background: white;
  border: 0;
}

.customDatePickerCalendar .react-datepicker__current-month {
  padding-top: 6px;
  padding-bottom: 16px;
  font-weight: 500;
}

.customDatePickerCalendar .react-datepicker__navigation {
  top: 18px;
}

.customDatePickerCalendar .react-datepicker__day-name {
  color: #969597;
}

.customDatePickerCalendar .react-datepicker__day--today {
  border-radius: 50%;
  outline: 1px solid#EAEAEC;
  font-weight: 400;
  background-color: #F8F8F8;
  color: #B7B7B9;
}
.customDatePickerCalendar .react-datepicker__day--today:hover {
  color: black !important;
}

.customDatePickerCalendar .react-datepicker__day--outside-month {
  color: #B7B7B9;
}

.customDatePickerCalendar .react-datepicker__triangle::after {
  border-bottom-color: white !important;
}

.customDatePickerCalendar .react-datepicker__day--selected,
.customDatePickerCalendar .react-datepicker__quarter-text--selected,
.customDatePickerCalendar .react-datepicker__year-text--selected{
  border-radius: 50%;
  background-color: #F3AC3C;
  color: white;
}

.customDatePickerCalendar .react-datepicker__day--keyboard-selected {
  background-color:white;
}

.customDatePickerCalendar .react-datepicker__day:hover,
.customDatePickerCalendar .react-datepicker__month-text:hover,
.customDatePickerCalendar .react-datepicker__quarter-text:hover,
.customDatePickerCalendar .react-datepicker__year-text:hover {
  border-radius: 50%;
  background-color: #FEFBE5;
  outline: 1px solid #F3AC3C;
}

.customDatePickerCalendar .react-datepicker__day:hover {
  color:black;
}

.react-datepicker__triangle{
  display: none !important;
}
/*ACTION BUTTONS*/
.save-notification {
  position: fixed;
  top: 100px;
  left: calc(50% - 190px);
  display: flex;
  align-items: center;
  align-items: center;
  background: white;
  z-index: 20;
  margin: 16px;
  padding: 10px 0px 10px 8px;
  border-radius: 10px;
  min-width: 344px;
  max-width: 500px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
              0px 6px 10px 0px rgba(0, 0, 0, 0.14),
              0px 1px 18px 0px rgba(95, 81, 81, 0.12);
  animation: slide-up 300ms ease-out forwards;
  overflow: hidden;
  border-bottom: 8px solid;
}

.save-notification-content {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.save-notification-dismiss {
  position: absolute;
  margin-left: auto;
  color: #969597;
  font-size: 125%;
  cursor: pointer;
  padding: 0px;
  top: 6%;
  right: 6%;
}

.save-notification-success {
  border-radius: 8px;
  border-bottom-color:  #45C781;
}

.save-notification-error {
  border-radius: 8px;
  border-bottom-color: #E55B06;
}
.input-header {
  position: relative;
  display: inline-block;
}

.edit-indicator {
  position: absolute;
  right: -16px;
  top: 5px;
  height: 8px;
  width: 8px;
  background-color: #E55B06;
  border-radius: 50%;
  content: "";
}

.edit-indicator-tab{
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  height: 12px;
  width: 12px;
  background-color: #E55B06;
  border-radius: 50%;
  content: "";
}

.actions-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.unsaved-changes-notification {
  display: flex;
  align-items: center;
  margin-right: auto;
  color:#E55B06;
  font-weight: 500;
}

.unsaved-changes-dot {
  height: 12px;
  width: 12px;
  background-color: #E55B06;
  border-radius: 50%;
  margin-right: 12px;
  margin-left: 24px;
}

.action-buttons {
  display: flex;
}

.btn-cancel, .btn-save {
  margin-left: 10px;
}

.type-button {
  position: relative;
  padding-right: 30px;
}

@keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.save-notification-label {
  margin: 8px 20px;
  line-height: 1.2rem;
}

.save-notification-header {
  font-size: 13px;
  min-width: 260px;
}

.save-notification-details {
  font-size: 12px;
  min-width: 250px;
}

.save-notification-details p {
  margin-top: 5px;
}

.save-notification-icon {
  scale: 0.9;
  height: auto;
  margin-left: 8px;
  margin-right: 8px;
  width: 24px;
  height: 24px;
}

.save-notification-message{
  padding-left: 8px;
  font-size:14px;
  font-weight: bold;
}
.summarySpecialText {
  cursor: pointer;
  color: var(--flg-purple);
}

.save-notification-message span {
  display: block;
}

.additional-error-message {
  padding-left: 8px;
  font-size: 12.5px;
  font-weight: normal;
}

.to-do {
  background-color: red;
}

.form-layout {
  display: flex;
  flex-wrap: wrap;
  padding:16px 0;
}

.form-column {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 300px;
}

.house-icon {
    margin-right: 16px;
}

.form-column > * {
  min-width: 93%;
  min-height: 73px;
}

@keyframes fadein {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeout {
  from { opacity: 1; }
  to { opacity: 0; }
}

/* RESPONSIVE */

@media only screen and (max-width: 1150px) {
  .col-8 {
    flex: 0 0 auto;
    width: 50% !important;
  }
  .f-wrapper {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
  }
  .input-container {
    display: inline-block;
    margin-top: 6px;
    width: calc(100% - 64px);
    margin-left: 32px;
    margin-right: 32px;
    margin-top: -10px;
  }
  .right-form .input-container:first-child {
    margin-top: 0px;
  }
}
