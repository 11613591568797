body {
  background: #f7f7f7;
  font-family: "Plus Jakarta Sans", sans-serif !important;
  font-family: "Poppins", sans-serif;
  font-family: "Roboto", sans-serif;
}

.login-page-wrapper {
  width: 100%;
}

.error-msg-wrapper {
  height: 110px;
  width: 386px;
  background-color: rgba(255, 255, 255);
  border-radius: 10px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  padding: 30px 40px;
  margin: auto auto;
  margin-top: 20px;
  text-align: center;
  font-weight: 900;
  box-shadow: 0px 4px 6px -2px #0000000d;
  box-shadow: 0px 10px 15px -3px #0000001a;
  transition: 0.15s;
}

label[for="username"] {
  color: #969597;
}

label[for="password"] {
  color: #969597;
}

label[for="version"] {
  color: #969597;
  position: fixed;
  bottom: 0;
  padding-bottom: 30px;
  padding-left: 30px;
  font-weight: bold;
  font-size: 12px;
}

.error-msg-wrapper label {
  display: block;
}

.error-msg-wrapper label.error-msg-header {
  font-weight: 800;
  color: black;
}
.error-msg-wrapper label.error-msg-prompt {
  margin-top: 4px;
  font-weight: 400;
  color: #9b9b9b;
}

.login-page-wrapper {
  height: 470px;
  width: 386px;
  background-color: rgba(255, 255, 255);
  border-radius: 10px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  padding: 50px 64px;
  margin: auto auto;
  margin-top: 20vh;
}
.login-page-wrapper img {
  width: 169px;
  height: 64px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 64px;
}

.login-page-wrapper label {
  font-weight: bold;
  font-size: 12px;
}

.login-page-wrapper input {
  font-size: 16px;
  color: #171526;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.login-page-wrapper .sign-in-btn {
  width: 100%;
  margin-top: 56px;
  height: 38px;
  border-radius: 8px;
  background: #f3ac3c;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
  border: 1px solid #c78d31;
}

.login-page-wrapper .username-input {
  margin-bottom: 25px;
  margin-top: 0px;
}

.login-page-wrapper .password-input {
  margin-top: 0px;
}

.login-page-wrapper input {
  display: block;
  height: 25px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.07);
  margin-top: 8px;
  outline: none;
  border: none;
  border-bottom: 1px solid gray;
}
