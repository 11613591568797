p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

#package-version {
    position: fixed;
    z-index: 2000;
    bottom: 8px;
    left: 8px;
    line-height: 100%;
    font-size: 12px;
    padding: 8px;
    background: #fff;
    color: rgb(99, 110, 130)
}

.top-bar-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  background: white;
  margin-left: 32px;
  margin-top: 20px;
  margin-right: 32px;
  border-radius: 12px;
}

.top-bar-wrapper div {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.top-bar-wrapper .top-bar-logo {
  padding: 20px 30px;
}

.top-bar-wrapper .top-bar-logo img {
  width: 82px;
  height: 31px;
}

.top-bar-wrapper .top-bar-nav {
  display: flex;
  justify-content: center;
  color: #f3ac3c;
}

.top-bar-wrapper .top-bar-nav .top-bar-nav-content {
  flex-grow: 0.05;
  align-items: center;
  justify-content: center !important;
  border-bottom: 2px solid #f3ac3c;
  margin-top: 10px;
  padding-bottom: 10px;
  font-weight: 500;
}

.top-bar-wrapper .top-bar-nav .top-bar-nav-content img {
  margin-right: 8px;
}

.top-bar-wrapper .top-bar-nav .top-bar-nav-content p {
  font-size: 16px;
}
.top-bar-wrapper .top-bar-logout {
  display: inline-flex;
  justify-content: flex-end;
}
.top-bar-wrapper .top-bar-logout .top-bar-logout-content {
  justify-content: flex-end;
  flex: 0 0 auto;
  margin-right: 40px;
  font-weight: 500;
  font-size: 16px;
  font-style: normal;
  width: min-content;
  cursor: pointer;
}

.top-bar-wrapper .top-bar-logout .top-bar-logout-content p {
  cursor: pointer;
  color: #969597;
}

.top-bar-wrapper .top-bar-logout .top-bar-logout-content svg {
  width: 26px;
  height: 24px;
  margin-right: 5px;
  color: #969597;
}

.top-bar-wrapper .top-bar-logout .top-bar-logout-content:hover p, .top-bar-wrapper .top-bar-logout .top-bar-logout-content:hover svg{
  color: #F3AC3C;
  transition: .15s;
}


.grid-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: white;
  margin-left: 32px;
  margin-top: 20px;
  margin-right: 32px;
  border-radius: 12px;
  margin-bottom: 34px;
}
table {
  border-spacing: 0px;
  width: 100%;
  table-layout: fixed;
}

.records-wrapper {
  border-spacing: 0px;
  width: 100%;
  table-layout: fixed;
}
.grid-top-table {
  width: 100%;
  background-color: #f9fafb;
  color: #969597;
  font-weight: 400;
  border-radius: 18px 18px 0px 0px;
}
.grid-top-table th:first-child {
    border-radius: 8px 0px 0px 0px;
  -moz-border-radius: 8px 80px 0px 0px;
  -webkit-border-radius: 8px 0px 0px 0px;
}

.grid-top-table th:last-child {
    border-radius: 0px 8px 0px 0px;
  -moz-border-radius: 0px 8px 0px 0px;
  -webkit-border-radius: 0px 8px 0px 0px;
}

.grid-top-table th {
  padding: 10px 20px;
  font-weight: 500;
  text-align: left;
  font-size: 14px;
}

.grid-record {
  border-radius: 6px;
  transition: .15s ease-in-out;
  cursor: pointer;
  outline: #F3AC3C;
}

.grid-record:hover {
  background: #F3AC3C26;
  outline: 1px solid #F3AC3C;
  border-radius: 6px;
}

.grid-record td {
  padding: 10px 20px;
  border-bottom: 1px solid #e8e6ea;
  font-size: 16px;
  font-weight: 500;
}

.grid-wrapper {
  margin-left: 32px;
  margin-right: 4px;
  margin-top: 32px;
}


/* SCROLLING TABLE */
.grid-wrapper::-webkit-scrollbar {
  width: 6px;
  margin-top: 20px;
}


.grid-wrapper::-webkit-scrollbar-track {
  background: #eaeaec;
  border-radius: 12px;
  margin-block: 15px;
}


.grid-wrapper::-webkit-scrollbar-thumb {
  background: #b7b7b9;
  border-radius: 6px;
}

.grid-wrapper::-webkit-scrollbar-thumb:hover {
  background: #b7b7b9;
}

.grid-wrapper {
  overflow-y: auto;
  overflow-x: hidden;
  height: 65vh;
  padding-left: 1px;
}
.grid-wrapper thead th {
  position: sticky;
  top: 0px;
}

table {
  border-collapse: collapse;
  width: 100%;
}
table tbody {
  padding-right: 20px;
  border-right: 28px solid transparent;
}
th,
td {
  padding: 8px 26px;
}
th {
  background: #eee;
}
.grid-wrapper::-webkit-scrollbar-button:start:decrement {
  height: 7%;
  display: block;
  background: transparent;
}
/* END SCROLLING TABLE */

.bc-wrapper {
  display: inline-block;
  margin-top: 20px;
  margin-left: 60px;
}

.bc-wrapper p {
  display: inline-block;
  vertical-align: middle;
  margin-right: 16px;
  color: #969597;
  cursor: default;
}

.bc-wrapper p.active {
  color: #f3ac3c;
  font-weight: 700;
  cursor: pointer;
}

.bc-wrapper p.non-active {
  font-weight: 600;
  cursor: pointer;
}

.bc-wrapper img {
  margin-right: 16px;
  cursor: pointer;
}

.page-control-wrapper {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: flex-end;
    margin: 32px 36px 32px 36px;
}

.page-control-wrapper div {
    display: flex;
    flex-grow: 1;
}

.page-control-wrapper .page-steps {
    justify-content: flex-end;
    font-size: 14px;
    color: #969597;
    margin-top: -7px;
}
.page-control-wrapper .current-page-wrapper span {
    color: #969597;
}
.page-steps p {
  cursor: pointer;
}
.page-previous {
  margin-right: 64px;
}

.page-previous::before {
  content:url('../images/chevron-left.svg');
  position:relative;
  top: 5px;
  right: 5px;
}

.page-next::after {
  content:url('../images/chevron-right.svg');
  position:relative;
  top: 5px;
  left: 5px;
}

.page-previous-disabled {
  margin-right: 64px;
  color: #cccccc;
  cursor: default !important;
}

.page-previous-disabled::before {
  content:url('../images/chevron-left-disabled.svg');
  position:relative;
  top: 5px;
  right: 5px;
}
.search-input-container {
  display: flex;
  align-items: center;
  position: relative;
  margin: 24px auto 0px auto;
  width: fit-content;
}

.search-input {
  font-size: 16px;
  padding: 10px 40px;
  border: 2px solid #B7B7B9;
  border-radius: 8px;
  width:520px;
}

.search-input:focus {
  border: 2px solid #F3AC3C;
  outline: none;
  font-weight: 500;
}


.search-input::placeholder {
  color: #B7B7B9;
  opacity: 1;
  font-weight: 300;
}

.search-icon, .clear-val-btn {
  position: absolute;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.search-icon {
  left: 14px;
  z-index: 10;
}

.clear-val-btn {
  right: 14px;
  z-index: 10;
}
