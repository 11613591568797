.textarea-input-container {
  display: inline-block;
  margin-top: 6px;
  width: calc(50% - 64px);
  margin-left: 32px;
  margin-right: 32px;
}

.form-textarea-custom {
  width: 100%;
  min-height: 33px;
  padding: 4px 0 4px 0;
  border: none;
  border-bottom: 1px solid #b7b7b9;
  outline: none;
  resize: vertical;
  background-color: transparent;
  overflow: hidden;
}

.form-textarea-custom:focus {
  border-bottom: 1px solid orange;
  transition: 0.15s ease-in-out;
}

.textarea-input-container:focus-within label {
  color: #f3ac3c;
  transition: 0.15s ease-in-out;
}