.form-readonly {
  display: block;
}

.form-readonly-list {
  display: block;
  padding: 4px 0;
  margin: 0;
  list-style-type: none;
}

.form-readonly-list li {
  padding: 0;
  margin: 0;
}

.form-readonly-custom {
  display: block;
  padding: 8px 0;
  margin: 0;
  list-style-type: none;
}


.form-readonly-custom li span{
  line-height: 36px;
}

.form-readonly-company-number {
  background-color: #3D3D3E;
  color: white;
  font-size: 12px;
  margin-left: 12px;
  padding: 6px;
  border-radius: 36px;
}